import { DOCUMENT } from "@angular/common";
import { Component, Inject, Renderer2 } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { SidenavService } from "./layout/sidenav/sidenav.service";
import { ThemeService } from "../@fury/services/theme.service";
import { ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { Platform } from "@angular/cdk/platform";
import { SplashScreenService } from "../@fury/services/splash-screen.service";
import { SidenavItem } from "./layout/sidenav/sidenav-item/sidenav-item.interface";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "fury-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  private permissions: string[] = [];

  constructor(
    private sidenavService: SidenavService,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private route: ActivatedRoute,
    private splashScreenService: SplashScreenService,
    private router: Router
  ) {
    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("style")))
      .subscribe((queryParamMap) =>
        this.themeService.setStyle(queryParamMap.get("style"))
      );

    this.iconRegistry.setDefaultFontSetClass("material-icons-outlined");
    this.themeService.theme$.subscribe((theme) => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updatePermissions();
      });
  }

  updatePermissions(): void {
    const storedPermissions = sessionStorage.getItem("permisos");
    if (storedPermissions) {
      this.permissions = storedPermissions
        .split(",")
        .map((permission) => permission.trim());
    } else {
      this.permissions = [];
    }
    this.sidenavService.clearItems();
    this.sidenavService.addItems(this.getMenuItems());
  }

  private getMenuItems(): SidenavItem[] {
    const userPermissions = this.permissions;
    const hasPermission = (permission: string): boolean => {
      /* const data = permission.replace(/_(DELETE|READ|WRITE)$/, "");

      if (userPermissions.includes(`${data}_DELETE`)) {
        return true;
      } else if (userPermissions.includes(`${data}_WRITE`)) {
        return true;
      } else if (userPermissions.includes(`${data}_READ`)) {
        return true;
      }
      return false; */
      return userPermissions.includes(permission);
    };

    const menuItems: SidenavItem[] = [
      {
        name: "",
        position: 1,
        type: "subheading",
        customClass: "first-subheading",
      },
      {
        name: "Dashboard",
        routeOrFunction: "/dashboard",
        icon: "dashboard",
        position: 2,
        permission: "GENERAL_READ",
      },
      {
        name: "Pedidos de pienso",
        icon: "dvr",
        position: 3,
        permission: "PIENSO_READ",
        subItems: [
          {
            name: "Añadir pedido",
            routeOrFunction: "/apps/add-pedido-pienso",
            position: 1,
            permission: "PIENSO_WRITE",
          },
          {
            name: "Histórico de pedidos",
            routeOrFunction: "/apps/pedidos-pienso",
            position: 2,
            permission: "PIENSO_READ",
          },
        ],
      },
      {
        name: "Pedidos de medicamentos",
        icon: "dvr",
        position: 4,
        permission: "MEDICAMENTOS_READ",
        subItems: [
          {
            name: "Añadir pedido",
            routeOrFunction: "/apps/add-pedido-medicamentos",
            position: 1,
            permission: "MEDICAMENTOS_WRITE",
          },
          {
            name: "Histórico de pedidos",
            routeOrFunction: "/apps/pedidos-medicamentos",
            position: 2,
            permission: "MEDICAMENTOS_READ",
          },
        ],
      },
      {
        name: 'Productos',
        icon: 'storage',
        position: 5,
        permission: "PRODUCTOS_READ",
        subItems: [
          {
            name: 'Histórico de productos',
            routeOrFunction: '/apps/historico-productos',
            position: 1,
            permission: "PRODUCTOS_READ",
          },
          {
            name: 'Añadir producto',
            routeOrFunction: '/apps/add-producto/null',
            position: 2,
            permission: "PRODUCTOS_WRITE",
          }             
        ]
      }, 
      {
        name: "Bajas de Animales",
        icon: "indeterminate_check_box",
        position: 6,
        permission: "BAJAS_READ",
        subItems: [
          {
            name: "Parte de bajas",
            routeOrFunction: "/apps/parte-bajas",
            position: 1,
            permission: "BAJAS_READ",
          },
          {
            name: "Histórico de bajas",
            routeOrFunction: "/apps/historico-bajas",
            position: 2,
            permission: "BAJAS_READ",
          },
        ],
      },

      {
        name: "Movimientos de granjas",
        icon: "swap_horiz",
        position: 7,
        permission: "MOVIMIENTOS_READ",
        subItems: [
          {
            name: "Movimientos de pienso",
            routeOrFunction: "/apps/movimientos-pienso",
            position: 1,
            permission: "MOVIMIENTOS_READ",
          },
          {
            name: "Movimientos de medicamentos",
            routeOrFunction: "/apps/movimientos-medicamentos",
            position: 2,
            permission: "MOVIMIENTOS_READ",
          },
          {
            name: "Movimientos de animales",
            routeOrFunction: "/apps/movimientos-animales",
            position: 3,
            permission: "MOVIMIENTOS_READ",
          },
        ],
      },
      {
        name: "Movimientos de animales",
        icon: "swap_vert",
        position: 8,
        permission: "MOVIMIENTOS_ANIMALES_READ",
        subItems: [
          {
            name: "Histórico de movimientos",
            routeOrFunction: "/apps/movimiento-animal",
            position: 1,
            permission: "MOVIMIENTOS_ANIMALES_READ",
          },
          {
            name: 'Añadir movimiento',
            routeOrFunction: '/apps/nuevo-movimiento-animal/null',
            position: 2,
            permission: "MOVIMIENTOS_ANIMALES_WRITE",
          },
          {
            name: 'Carga transportista',
            routeOrFunction: "/apps/carga-transportista",
            position: 3,
            permission: "MOVIMIENTOS_ANIMALES_READ",
          }
         
        ]
      },        

      // {
      //   name: "Recetas",
      //   icon: "description",
      //   position: 9,
      //   permission: "RECETAS_READ",
      //   subItems: [
      //     {
      //       name: "Añadir receta",
      //       routeOrFunction: "/apps/add-receta",
      //       position: 1,
      //       permission: "RECETAS_WRITE",
      //     },
      //     {
      //       name: "Histórico de recetas",
      //       routeOrFunction: "/apps/historico-recetas",
      //       position: 2,
      //       permission: "RECETAS_READ",
      //     },
      //   ],
      // },

      /* {
            name: 'Geolocalización',
            routeOrFunction: '/apps/geolocalizacion',
            icon: 'place',
            /* badge: '22',
            badgeColor: '#2196F3',
            position: 9,
          },  */

      {
        name: "Control de almacén",
        icon: "assignment",
        position: 10,
        permission: "ALMACEN_READ",
        subItems: [
          {
            name: "Artículos",
            routeOrFunction: "/apps/control-almacen/articulos",
            position: 1,
            permission: "ALMACEN_READ",
          },
          {
            name: "Añadir artículo",
            routeOrFunction: "/apps/control-almacen/add-articulo/null",
            position: 2,
            permission: "ALMACEN_WRITE",
          },
          // {
          //   name: "Importar CSV",
          //   routeOrFunction: "/apps/control-almacen/importar-csv",
          //   position: 3,
          //   permission: "ALMACEN_READ",
          // },
        ],
      },

      {
        name: "Auditorías",
        icon: "insert_chart",
        position: 11,
        permission: "AUDITORIAS_READ",
        subItems: [
          {
            name: "Estadísticas de auditoría",
            routeOrFunction: "/apps/estadisticas-auditoria",
            position: 1,
            permission: "AUDITORIAS_READ",
          },
          {
            name: "Plantillas de auditoría",
            routeOrFunction: "/apps/plantilla-auditoria",
            position: 2,
            permission: "AUDITORIAS_READ",
          },
          {
            name: "Añadir plantilla auditoría",
            routeOrFunction: "/apps/add-plantilla-auditoria/null",
            position: 3,
            permission: "AUDITORIAS_WRITE",
          },
          {
            name: "Auditorías de granjas",
            routeOrFunction: "/apps/auditorias-granja",
            position: 4,
            permission: "AUDITORIAS_READ",
          },
          {
            name: "Añadir auditoría de granjas",
            routeOrFunction: "/apps/auditoria-granja",
            position: 5,
            permission: "AUDITORIAS_WRITE",
          },
          {
            name: "Regas de granjas",
            routeOrFunction: "/apps/regas-granja",
            position: 6,
            permission: "AUDITORIAS_READ",
          },
          {
            name: "Añadir rega granja",
            routeOrFunction: "/apps/add-regas-granja/null",
            position: 7,
            permission: "AUDITORIAS_WRITE",
          },
        ],
      },

      {
        name: "Notificaciones",
        icon: "notifications",
        position: 12,
        permission: "NOTIFICACIONES_READ",
        subItems: [
          {
            name: "Notificaciones",
            routeOrFunction: "/apps/notificaciones",
            position: 1,
            permission: "NOTIFICACIONES_READ",
          },
          {
            name: "Añadir notificación",
            routeOrFunction: "/apps/add-notificaiones",
            position: 2,
            permission: "NOTIFICACIONES_WRITE",
          },
          {
            name: "Histórico notificaciones usuario",
            routeOrFunction: "/apps/historico-notificaiones",
            position: 3,
            permission: "NOTIFICACIONES_READ",
          },
          {
            name: "Días de pedido granja",
            routeOrFunction: "/apps/dias-pedido-granja",
            position: 4,
            permission: "NOTIFICACIONES_READ",
          },
          {
            name: "Añadir días de pedido granja",
            routeOrFunction: "/apps/add-dias-pedido-granja/null",
            position: 5,
            permission: "NOTIFICACIONES_WRITE",
          },
          {
            name: "Movimientos vaciado nave",
            routeOrFunction: "/apps/movimiento-vaciado-nave",
            position: 6,
            permission: "NOTIFICACIONES_READ",
          },
          {
            name: "Estadísticas de granjas",
            routeOrFunction: "/apps/estadisticas-granja",
            position: 7,
            permission: "NOTIFICACIONES_READ",
          },
        ],
      },
      {
        name: "Informes",
        icon: "view_list",
        position: 13,
        permission: "PLANTILLAS_READ",
        subItems: [
          {
            name: "Plantilla de informes",
            routeOrFunction: "/apps/plantilla-informes",
            position: 1,
            permission: "PLANTILLAS_READ",
          },
          {
            name: "Añadir plantilla de informe",
            routeOrFunction: "/apps/nueva-plantilla-informe/null",
            position: 2,
            permission: "PLANTILLAS_WRITE",
          },
          {
            name: "Informe traslados autorizados",
            routeOrFunction: "/apps/informe-traslados",
            position: 3,
            permission: "PLANTILLAS_WRITE",
          },
        ],
      },
      {
            name: 'Sellos',
            icon: 'brightness_high',
            position: 14,
            permission: "SELLOS_READ",
            subItems: [
              {
                name: 'Histórico de sellos',
                routeOrFunction: '/apps/historico-sellos',
                position: 1,
                permission: "SELLOS_READ",
              },
              {
                name: 'Añadir sello',
                routeOrFunction: '/apps/add-sello/null',
                position: 2,
                permission: "SELLOS_WRITE",
              }
            ]
          },
      {
        name: "Usuarios",
        routeOrFunction: "/apps/usuarios",
        icon: "supervisor_account",
        position: 15,
        permission: "USUARIOS_READ",
      },

      {
        name: "Configuración",
        routeOrFunction: "/apps/configuracion",
        icon: "settings",
        position: 16,
        permission: "GEOLOCALIZACION_READ",
      },

      {
        name: "Espacio virtual",
        icon: "folder_open",
        position: 17,
        permission: "ESPACIO_VIRTUAL_READ",
        subItems: [
          {
            name: "Carpetas",
            routeOrFunction: "/apps/virtual/carpetas",
            position: 1,
            permission: "ESPACIO_VIRTUAL_READ",
          },
          {
            name: "Carpetas admin",
            routeOrFunction: "/apps/virtual/carpetas-admin",
            position: 2,
            permission: "ESPACIO_VIRTUAL_READ",
          },
          {
            name: "Añadir carpeta",
            routeOrFunction: "/apps/virtual/add-carpeta/null",
            position: 3,
            permission: "ESPACIO_VIRTUAL_WRITE",
          },
        ],
      },
      {
        name: "Roles y permisos",
        icon: "assignment_turned_in",
        position: 18,
        permission: "ROLES_READ",
        subItems: [
          {
            name: "Añadir roles",
            routeOrFunction: "/apps/add_roles/null",
            position: 1,
            permission: "ROLES_WRITE",
          },
          {
            name: "Histórico roles",
            routeOrFunction: "/apps/historico_roles",
            position: 2,
            permission: "ROLES_READ",
          },
        ],
      },
      {
        name: "Cerrar sesión",
        routeOrFunction: "/logout",
        icon: "exit_to_app",
        position: 19,
      },
    ];

    const filteredMenuItems = menuItems
      .filter((item) => {
        return !item.permission || hasPermission(item.permission);
      })
      .map((item) => ({
        ...item,
        subItems: item.subItems?.filter((subItem) => {
          return !subItem.permission || hasPermission(subItem.permission);
        }),
      }));
    return filteredMenuItems;
  }
}
